import Image from "../components/image"
import PropTypes from "prop-types"
import React from "react"
import CookieConsent from "react-cookie-consent";

function Footer({ siteTitle }) {
  return (
    <footer
      className={`w-full bg-gray-800 text-white text-sm sm:text-xs lg:text-base`}
    >
      <div className={`container flex flex-wrap mx-auto py-6`}>
        <div className={`w-full sm:w-1/3 px-10 sm:px-5 md:pl-10 md:pr-0`}>
          <a
            className={`font-bold hover:text-gray-500`}
            href="mailto:andrew@bristolcounsellingservices.co.uk"
          >
            <svg
              className={`h-5 fill-current hover:text-gray-500 inline-block mr-2`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"></path>
            </svg>
            Andrew Newton
          </a>
          <p className={`pl-6 ml-1`}>The Practice Rooms,</p>
          <p className={`pl-6 ml-1`}>55 Queens Square,</p>
          <p className={`pl-6 ml-1 w-full`}>Bristol, BS1 4LH</p>
          <a
            className={`hover:text-gray-500 font-bold pl-6 ml-1`}
            href="tel:+7834991034"
          >
            0783 499 1034
          </a>
        </div>
        <div className={`w-full sm:w-1/3`}>
          <a href="https://www.bacp.co.uk/profile/4085aeb6-01dc-e711-80f1-3863bb349ac0/Therapist" aria-label="BACP profile" className={`bg-white p-5 my-5 block mx-10 sm:m-0 sm:p-2 md:p-0 md:mx-5 lg:mx-10 xl:mx-20`}>
            <Image
              className="border-8 border-white border-solid"
              filename="bacp-378851.png"
            />
          </a>
        </div>
        <div
          className={`flex flex-wrap w-full sm:w-1/3 px-10 sm:pl-0 sm:pr-5 md:pr-10 md:pl-0 text-center justify-center sm:text-right sm:content-between sm:justify-end`}
        >
          <nav>
            <ul
              className={`inline-flex xm-0 xsm:w-auto xmx-auto lg:mr-0 xsm:flex-row xsm:items-center lg:text-base text-white mb-1 xmy-4`}
              id="footer-menu"
            >
              <li className={``}>
                <a href="/privacy" className={`hover:text-gray-400`}>
                  Privacy
                </a>
              </li>
              <li className={``}>
                <a href="/cookies" className={`hover:text-gray-400`}>
                  Cookies
                </a>
              </li>
              <li className={``}>
                <a href="/digital" className={`hover:text-gray-400`}>
                  Digital Policy
                </a>
              </li>
            </ul>
          </nav>
          <div className={`w-full leading-snug`}>
            <p className={`lg:text-sm`}>
              © {new Date().getFullYear()}, Andrew Newton
            </p>
            <p className={`lg:text-sm`}>
              Design & Development by{" "}
              <a
                className={`hover:text-gray-700 underline`}
                href="https://www.blu.uk"
              >
                BLU
              </a>
            </p>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="BCSCookie"
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        style={{ background: "#485563" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
